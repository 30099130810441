<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:1000px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">DEM/DET Invoice</h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left col_3">
            <table class="tbl_list font_sm gridtype">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('msg.REPORT_PDF.002') }}<!-- B/L No. --></th>
                  <th>{{ $t('msg.REPORT_PDF.004') }}<!-- Invoice No. --></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in items" :key="'pdf_' + idx">
                  <td>{{ vo.blNo }}</td>
                  <td>{{ vo.invIssNo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float_left text_center col_7 pl20" style="max-height:500px;overflow-y:auto">
            <!-- <div>{{ pdf.currentPage }} / {{ pdf.pageCount }}</div> -->
            <vue-pdf-app
              style="height: 300px;"
              :pdf="pdf.pdfUrl"
              :config="pdfConfig.config"
              :id-config="pdfConfig.idConfig"
              theme="light"
            ></vue-pdf-app>
            <div class="paging print">
              <span class="num">
                <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
        <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
      </div>
    </div>

    <win-layer-pop class="pdf_send_email">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closeSendEmailPopup"
        :parent-info="sendEmailParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import reportInfo from '@/api/rest/trans/reportInfo'
import commonPdf from '@/plugins/commonPdf'
import 'vue-pdf-app/dist/icons/main.css'

const REPORT_URL = process.env.VUE_APP_REPORT_URL
const SETTLE_URL = process.env.VUE_APP_SETTLE_URL

export default {
  name: 'DemInvoicePop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      items: [],
      userInfo: {},
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo: {
      deep: true,
      handler () {
       this.setItems()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      this.setItems()

      commonPdf.commonInit()
    },
    async setItems () {
      const items = this.parentInfo.items
      this.items = []

      const params = {
        arrBlNo: items.map(vo => vo.blNo),
        demDetCatCd: this.parentInfo.demDetCatCd,
        eiCatCd: this.parentInfo.eiCatCd,
        ctrCd: this.memberDetail.userCtrCd,
        agtCd: '' // reportInfo.getReportInfoUser 호출후 값 셋팅
      }

      await Promise.all([
        reportInfo.getReportInfoUser('')
          .then(async res => {
            this.userInfo = res.data
          }),
        reportInfo.getDemDetInvoice(params)
          .then(async res => {
            items.forEach(vo => {
              const list = res.data[vo.blNo]
              if (list !== undefined && list.length > 0) {
                list.forEach(tvo => {
                  let invRno = tvo.invRno || ''
                  let invIssNo = tvo.invIssNo || ''

                  if (invIssNo === '') {
                    invIssNo = invRno
                  }
                  this.items.push({
                    blNo: vo.blNo,
                    invRno: invRno || '',
                    invIssNo: invIssNo || ''
                  })
                })
              } else {
                this.items.push({
                  blNo: vo.blNo,
                  invRno: '',
                  invIssNo: ''
                })
              }
            })
          })
      ])

      params.agtCd = this.userInfo.agtCd

      this.pdf = await commonPdf.setDemInvoiceMulti(this.items, params)
    },
    fnDownlaod () {
      const items = this.items || []

      if (items.length === 0) {
        return
      }

      items.forEach(p => {
        commonPdf.download(p.pdfUrl, 'KMTC' + p.blNo + '.pdf')
      })
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    }
  }
}
</script>

<style>

body {
  min-width:100% !important;
  overflow: auto !important;
}

</style>
